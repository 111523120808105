<template>
  <form @submit.prevent="validateForm()" data-vv-scope="algorithm">
    <cytomine-modal :active="active" :title="title">
      <b-field :label="$t('files')" horizontal :type="{ 'is-danger': errors.has('algorithm.file') }"
        :message="errors.first('algorithm.file')">
        <div>
          <div class="is-flex" style="align-items: center; justify-content: space-between;">
            <b-input v-model="filename" name="file" v-validate="'required'" style="display: none;" />
            <b-upload :value="plainFile" type="is-link" drag-drop @input="filesChange" accept=".zip">
              <a class="button is-success">{{ $t('add-files') }}</a>
            </b-upload>
            <template v-if="algorithm.dropFile.file !== null">
              <td>{{ algorithm.dropFile.file.name }}</td>
              <td>{{ filesize(algorithm.dropFile.file.size) }}</td>
              <template v-if="algorithm.dropFile.uploadedFile !== null">
                <uploaded-file-status v-if="algorithm.dropFile.uploadedFile" :file="algorithm.dropFile.uploadedFile" />
                <span v-else class="tag is-danger">
                  {{ $t('upload-error') }}
                </span>
              </template>
            </template>
          </div>
          <div class="is-flex" style="align-items: center; justify-content: space-between;">
            <template v-if="algorithm.dropFile.uploading">
              <template v-if="algorithm.dropFile.uploadedFile === null">
                <progress class="progress is-info" :value="algorithm.dropFile.progress" max="100">
                  {{ algorithm.dropFile.progress }}%
                </progress>
              </template>
            </template>
          </div>
        </div>
      </b-field>

      <b-field :label="$t('version')" horizontal :type="{ 'is-danger': errors.has('algorithm.version') }"
        :message="errors.first('algorithm.version')">
        <b-input v-model="algorithm.version" name="version" v-validate="'required'" />
      </b-field>

      <template #footer>
        <slot name="footer">
          <button class="button" type="button" @click="$emit('update:active', false)">
            {{ $t('button-close') }}
          </button>
          <template v-if="deploying">
            <button class="button is-link" :disabled="true">
              <i class="fas fa-spinner fa-spin"></i>
            </button>
          </template>
          <template v-else-if="started">
            <button class="button is-danger" @click="cancelUpload()">
              {{ $t('button-cancel') }}
            </button>
          </template>
          <template v-else>
            <button class="button is-link" :disabled="!(!started && !errors.any('algorithm'))" @click="startUpload()">
              {{ $t('button-start') }}
            </button>
          </template>
        </slot>
      </template>
    </cytomine-modal>
  </form>
</template>

<script>
import { UploadedFileStatus } from 'cytomine-client';
import CytomineModal from '@/components/utils/CytomineModal';
import { get } from '@/utils/store-helpers';
import axios from 'axios';
import filesize from 'filesize';

import UploadedFileStatusComponent from '@/components/storage/UploadedFileStatus';
import UploadedFileDetails from '@/components/storage/UploadedFileDetails';

export default {
  name: 'add-software-modal',
  props: {
    active: Boolean
  },
  components: {
    CytomineModal,
    'uploaded-file-status': UploadedFileStatusComponent,
    UploadedFileDetails,
  },
  $_veeValidate: { validator: 'new' },
  data() {
    return {
      algorithm: {
        version: '',
        dropFile: {
          file: null,
          uploading: false,
          progress: 0,
          uploadedFile: null,
          cancelToken: null
        },
        result: null,
      },
      started: false,
    };
  },
  computed: {
    currentUser: get('currentUser/user'),
    title() {
      return this.$t('new-algorithm');
    },
    plainFile() {
      return this.algorithm.dropFile.file;
    },
    filename() {
      return this.algorithm.dropFile.file ? this.algorithm.dropFile.file.name : '';
    },
    deploying() {
      return this.algorithm.dropFile.uploadedFile !== null && this.algorithm.dropFile.uploadedFile.status && this.algorithm.dropFile.uploadedFile.status === UploadedFileStatus.DEPLOYING;
    },
    uri() {
      return '/algorithms/upload';
    },
    queryString() {
      return `dry_run=false&version=${this.algorithm.version}`;
    },
  },
  methods: {
    async validateForm() {
      let result = await this.$validator.validateAll('algorithm');
      if (!result) {
        return false;
      }

      return true;
    },
    filesChange(file) {
      if (!file.processed) {
        file.processed = true;
        this.algorithm.dropFile = {
          file,
          uploading: false,
          progress: 0,
          uploadedFile: null,
          cancelToken: null
        };
      }
    },
    filesize(size) {
      return (size) ? filesize(size, { base: 10 }) : null;
    },
    async startUpload() {
      if (!(await this.validateForm())) {
        return;
      }

      this.started = true;
      let fileWrapper = this.algorithm.dropFile;
      fileWrapper.uploadedFile = null;

      if (fileWrapper.uploading || fileWrapper.uploadedFile !== null) {
        return;
      }

      let formData = new FormData();
      formData.append('file', fileWrapper.file);
      fileWrapper.cancelToken = axios.CancelToken.source();
      fileWrapper.uploading = true;

      axios.post(
        this.uri + '?' + this.queryString,
        formData,
        {
          headers: {
            'authorization': `CYTOMINE ${this.currentUser.publicKey}:${this.signature}`,
            'dateFull': this.signatureDate, // will replace actual date value, so that signature is valid
            'content-type-full': 'null' // will erase actual content-type value, so that signature is valid
          },
          onUploadProgress: progress => {
            fileWrapper.progress = Math.floor((progress.loaded * 100) / progress.total);
            if (fileWrapper.progress === 100) {
              fileWrapper.uploadedFile = {
                status: UploadedFileStatus.DEPLOYING,
              };
            }
          },
          cancelToken: fileWrapper.cancelToken.token
        }
      ).then(response => {
        this.algorithm.result = response.data;
        fileWrapper.uploadedFile = {
          status: UploadedFileStatus.DEPLOYED,
        };
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-algorithm-creation', { algorithmName: `${this.algorithm.result.name}:${this.algorithm.result.softwareVersion}` })
        });
      }).catch(error => {
        if (!axios.isCancel(error)) {
          console.log(error);
          if (error.response && error.response.data && error.response.data.detail) {
            fileWrapper.uploadedFile = {
              status: UploadedFileStatus.ERROR_DEPLOYMENT,
            };
            this.$notify({
              type: 'error',
              text: error.response.data.detail
            });
          }
          else {
            fileWrapper.uploadedFile = false;
          }
        }
      }).finally(() => {
        fileWrapper.uploading = false;
        this.started = false;
      });
    },
    cancelUpload() {
      let fileWrapper = this.algorithm.dropFile;
      if (fileWrapper.cancelToken) {
        fileWrapper.cancelToken.cancel();
      }
      fileWrapper.uploading = false;
      fileWrapper.uploadedFile = false;
      this.started = false;
    },
  }
};
</script>
